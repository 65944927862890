import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'
import { Gallery } from '../components/Gallery';
import { HTMLContent, HTMLContentConverter } from '../components/CMS/Content'

export const GalleryPageTemplate = ({ description, images }) => {
  const photos = images.map(img => {
    return {
      title: img.title,
      src: typeof img.src === 'object' && img.src !== null ? img.src.publicURL : img.src, 
      width: img.width ? img.width : 4,
      height: img.height ? img.height : 3,
    }
  })
  return (
    <section className="section section--gradient textured-bg">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section page-content">
              <HTMLContentConverter content={description} />
              <Gallery photos={photos} />
              <br/>
              <hr/>
              <iframe width="100%" height="500" src="https://www.youtube.com/embed/yyxcuSifsqs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

GalleryPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const GalleryPage = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
    <Layout title={post.frontmatter.title} location={location}>
      <GalleryPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        images={post.frontmatter.images}
        description={post.frontmatter.description}
      />
    </Layout>
  )
}

GalleryPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default GalleryPage

export const galleryPageQuery = graphql`
  query GalleryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        images {
          title
          src {
            publicURL
          }
          width
          height
        }
      }
    }
  }
`
